// import './error.scss';

function ErrorPage() {
  return (
    <div id="error" className="error">
      <div className="container about">
        <h1>Erreur 404</h1>
        <p>Oups 🙈 Cette page n'existe pas</p>
      </div>
    </div>
  );
}

export default ErrorPage;

